<template>
    <div class="baseTemplate">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">{{ localSiteName }}</h3>
            <router-link
                :to="{ name: 'subscription', params: { siteId: this.$route.params.siteId } }"
                v-if="requestSucceeded && hasSubscription == false"
                class="labelSubscriptionState kt-badge kt-badge--inline kt-badge--gray cursorPointer"
            >{{ $t("subscription_noActiveSubscription") }}</router-link>
            <router-link
                :to="{ name: 'subscription', params: { siteId: this.$route.params.siteId } }"
                v-if="isTrialSubscription && !isSubscriptionTerminated"
                class="labelSubscriptionState kt-badge kt-badge--inline kt-badge--success cursorPointer"
            >
                {{ $t("subscription_trialActive") }} ({{ $t("common_until") }} {{ this.subscriptionEndDate }})
                <span
                    class="pipeMargin"
                >|</span>
                {{ $t("common_validFor") }}: {{ this.getActiveLicense && this.getActiveLicense.totalM2 }}{{ $t("common_sqM") }}
            </router-link>
            <router-link
                :to="{ name: 'subscription', params: { siteId: this.$route.params.siteId } }"
                v-if="isTrialSubscription && isSubscriptionTerminated"
                class="labelSubscriptionState kt-badge kt-badge--inline kt-badge--danger cursorPointer"
            >{{ $t("subscription_trialActive") }} {{ $t("common_finishedSince") }} {{ this.subscriptionEndDate }}</router-link>
            <router-link
                :to="{ name: 'subscription', params: { siteId: this.$route.params.siteId } }"
                v-if="isPaidSubscription && !isSubscriptionTerminated"
                class="labelSubscriptionState kt-badge kt-badge--inline kt-badge--success cursorPointer"
            >
                {{ $t("subscription_activeSubscription") }} ({{ $t("common_until") }} {{ this.subscriptionEndDate }})
                <span
                    class="pipeMargin"
                >|</span>
                {{ $t("common_validFor") }}: {{ this.getActiveLicense && this.getActiveLicense.totalM2 }}{{ $t("common_sqM") }}
            </router-link>
            <router-link
                :to="{ name: 'subscription', params: { siteId: this.$route.params.siteId } }"
                v-if="isPaidSubscription && isSubscriptionTerminated"
                class="labelSubscriptionState kt-badge kt-badge--inline kt-badge--danger cursorPointer"
            >{{ $t("subscription_subscription") }} {{ $t("common_finishedSince") }} {{ this.subscriptionEndDate }}</router-link>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <h3
                v-if="this.currentBuilding"
                class="kt-subheader__title"
            >{{ this.currentBuilding.name }}</h3>
            <span
                v-if="this.currentBuilding"
                class="kt-subheader__separator kt-subheader__separator--v"
            ></span>
            <h3
                v-if="this.currentFloor.name"
                class="kt-subheader__title"
            >{{ this.currentFloor.name }}</h3>
            <span
                v-if="this.currentFloor.name"
                class="kt-subheader__separator kt-subheader__separator--v"
            ></span>
            <span class="kt-subheader__desc">{{ $t(this.subHeaderDescKey) }}</span>
        </div>

        <div
            v-if="this.requestSucceeded && this.$route.name == 'siteOverview' && this.hasSubscription && this.isTrialSubscription && hasAccess(this.siteId, 'SITE_CONFIGURATION.SITE_INFORMATIONS')"
            class="kt-subheader__toolbar"
        >
            <div class="kt-subheader__wrapper">
                <router-link
                    :to="{ name: 'subscription' }"
                    href="#"
                    class="btn btn-primary btn-outline btn-sm getPaidAccountButton"
                >
                    <i class="fa fa-mouse-pointer"></i>
                    {{ $t("subscription_activatePaidSubscription") }}
                </router-link>
            </div>
        </div>

        <div
            v-if="this.requestSucceeded && this.$route.name == 'siteOverview' && this.hasSubscription == false && hasAccess(this.siteId, 'SITE_CONFIGURATION.SITE_INFORMATIONS')"
            class="kt-subheader__toolbar"
        >
            <div class="kt-subheader__wrapper">
                <router-link
                    :to="{ name: 'subscription', params: { siteId: this.$route.params.siteId } }"
                    href="#"
                    class="btn btn-primary btn-outline btn-sm getPaidAccountButton"
                >
                    <i class="fa fa-mouse-pointer"></i>
                    {{ $t("subscription_start3MonthTrial") }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import commonVueHelper from "../../../helpers/commonVueHelper";

export default {
    data() {
        console.log("Component(SiteTitle)::data() - called");
        return {
            siteId: this.$route.params.siteId,
            buildingId: this.$route.params.buildingId,
            floorId: this.$route.params.floorId,
            localSiteName: null,
            dashboardId: this.$route.params.dashboardId,
            hasSubscription: false,
            isTrialSubscription: false,
            isPaidSubscription: false,
            requestSucceeded: false
        };
    },
    created: function() {

        console.log("Component(SiteTitle)::created() - called with id: " + this.siteId);

        this.getSiteById(this.siteId);

        var sync = this.$route.name === "subscription" ? true : false;
        var data = { siteId: this.siteId, sync: sync };
        this.getSiteSubscription(data);

        if (this.buildingId && !this.currentBuilding) {
            const payload = {
                siteId: this.siteId,
                buildingId: this.buildingId
            };
            this.getBuildingById(payload);
        }
        if (this.floorId && !this.currentFloor.name) {
            const payload = {
                siteId: this.siteId,
                buildingId: this.buildingId,
                floorId: this.floorId
            };
            this.getFloorById(payload);
        }
    },
    mounted: function() {
        console.log("Component(SiteTitle)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(SiteTitle)::destroyed() - called");
    },
    props: {
        subHeaderDescKey: {
            type: String
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        currentSite() {
            if (this.currentSite) {
                this.localSiteName = this.currentSite.name;
            }
        },

        siteSubscription() {
            this.requestSucceeded = true;
            if (_.isEmpty(this.siteSubscription)) {
                // No subscription for this site yet
                this.hasSubscription = false;
            } else {
                this.hasSubscription = true;
                if (this.siteSubscription.type === "TRIAL") {
                    this.isTrialSubscription = true;
                    this.isPaidSubscription = false;
                } else if (this.siteSubscription.type === "PAID") {
                    this.isTrialSubscription = false;
                    this.isPaidSubscription = true;
                }
                this.subscriptionEndDate = moment(
                    this.siteSubscription.endDate
                ).format(
                    commonVueHelper.getShortDateStringFormatFromLocale(
                        moment().locale()
                    )
                );
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "currentSite",
            "currentBuilding",
            "currentFloor",
            "siteSubscription",
            "isSubscriptionTerminated",
            "getActiveLicense",
            "hasAccess"
        ])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSiteById",
            "getBuildingById",
            "getFloorById",
            "getSiteSubscription"
        ])
    },
    components: {},
    filters: {}
};
</script>

<style>
.baseTemplate {
    display: contents;
}
.getPaidAccountButton {
    background-color: #00c4ff;
    border-color: #00c4ff;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);
}
.getPaidAccountButton:hover {
    background-color: #06b9ef;
    border-color: #06b9ef;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);
}
.getPaidAccountButton:active {
    background-color: #06b9ef !important;
    border-color: #06b9ef !important;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.7);
}
.labelSubscriptionState {
    margin-right: 15px;
    font-weight: 600;
}
.pipeMargin {
    margin-left: 7px;
    margin-right: 7px;
}
</style>
